jQuery(function($){

var app = {
	windowLoaded: function () {
		_main = this;
		time = $('.guest').length?2000:400;
		setTimeout(function(){
			$('.preloader').fadeOut(500, function () {
				// $(this).remove();
			});
		}, time);		
	},
	windowResized: function () {
		_main = this;
		_main.getViewport();
	},
	windowScrolled: function () {
		_main = this;
	},
	cookies: {		
		createCookie: function(name, value, days) {		    
		    _main=app;
		    var date = new Date();
		    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		    var expires = "; expires=" + date.toGMTString();
		    document.cookie = name + "=" + value + expires + "; path=/";
		    (_main.debug)?console.log('creating cookie: ',name):'';
		},
		readCookie: function(name) {
		    _main=app;
		    var nameEQ = name + "=";
		    var ca = document.cookie.split(';');
		    for (var i = 0; i < ca.length; i++) {
		    	var c = ca[i];
		    	while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		    	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		    }
		    return null;
		},
		checkCookies: function() {
		    _main=app;
		    if (_main.cookies.readCookie(_main.cookieName) != 1) {
		      (_main.debug)?console.log('cookie %s is not created yet',_main.cookieName):'';
		      _main.cookies.notification('show');
		    } else {
		    	(_main.debug)?console.log('cookie %s already exists',_main.cookieName):'';
		    }
		    $('.closeNotification').on('click',function () {
		      _main.cookies.createCookie(_main.cookieName, 1, 365);
		      _main.cookies.notification('hide');
		    });
		},
		notification: function(state) {
			_main=app;
			switch (state) {
				case 'show':
					$('footer').append('<div class="cookiesNotification"><p>Na stronie używamy plików cookies.</p><p class="closeNotification">OK</p></div>');
				break;
				case 'hide':					
					$('.cookiesNotification').fadeOut(300, function () {
				        $(this).remove();
				      });
				break;
			}
		}
	},	
	handleSliders: function() {
		var _main=this;
		if (_main.viewport == 'mobile' || _main.viewport == 'tablet') {
			if ($('.pre-header').length) {			
				$('.pre-header').slick({
					arrows: false,
					dots: false,
					swipe: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplaySpeed: 3000,
					autoplay: true,
					fade: false,
					pauseOnFocus: false,
					pauseOnHover: false
				});			
			}
		}		
	},	
	disableScroll: function() {
		// Get the current page scroll position
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
	  
		// if any scroll is attempted, set this to the previous value
		window.onscroll = function() {
			window.scrollTo(scrollLeft, scrollTop);
		};
		_main.debug?console.log('disabling scroll'):'';
	},	  
	enableScroll: function() {
		window.onscroll = function() {};
		_main.debug?console.log('enabling scroll'):'';
	},
	popMessage: function(message) {
		var _main=this;
		switch (message) {
			case 'cart_add':
				_main.killPops();
				_main.getField('pop_info_cart_add');				
			break;
			case 'cart_error':
				_main.killPops();
				_main.getField('pop_info_cart_error');				
			break;
			case 'error':
				_main.killPops();
				_main.getField('error_general');				
			break;
			case 'loading':
				$('body').append('<div class="pop-box"><div class="lds-dual-ring"></div></div>');
			break;
		}		
	},
	killPops: function() {
		$('.pop, .pop-box, .pop-cart, .pop-message, .popup').remove();
	},	
	handleGalleries: function() {		
		if ($('.has-lightbox-gallery').length) {
			_main.debug?console.log('gallery init'):'';
					lightbox.option({
						'resizeDuration': 200,
						'wrapAround': true,
						'albumLabel': 	"Ilustracja %1 z %2",
						'disableScrolling': 	true
					});
		}
	},
	events: function () {
		_main = this;
		
		$('.pd').on('click', function(e) {
			e.preventDefault();
		});		

		$('.widget-call .close').on('click', function() {
			$('.widget-call').fadeOut(500, function() {
				$('.widget-call').remove();
			});
		});

		$('.hamburger').on('click', function() {
			if (_main.hasNav == true) {
				_main.hasNav = false;
				_main.enableScroll();
			} else {
				_main.hasNav = true;
				_main.disableScroll();
			}
			_main.debug?console.log('current nav: ',_main.hasNav):'';
			$(this).toggleClass('active');	
			$('nav').toggleClass('active');	
			$('body').toggleClass('has-big-nav');
		});

		// $('.form-toggle').on('click', function() {
		// 	$(this).toggleClass('open');
		// 	$('.form-wrapper').slideToggle();
		// });

		// $('#ship-to-different-address-checkbox').on('click', function() {
		// 	$('.shipping_address').toggleClass('avalible');
		// });

		// $('.btn-add-to-cart').on('click', function(e) {			
		// 	e.preventDefault();
		// 	var target = $(this).attr('href');
		// 	var qty = $('#qty').val();
		// 	_main.debug?console.log('going: ', target):'';
		// 	_main.debug?console.log('qty: ', qty):'';
		// 	if (qty > 1) {
		// 		target += '&quantity='+qty;
		// 	}
		// 	_main.popMessage('loading');
		// 	$.ajax({
		// 		type: "GET",
		// 		url: target,
		// 		success: function(response) {
		// 			_main.debug?console.log('ok'):'';					
		// 			_main.popMessage('cart_add');
		// 			// localStorage.popInfo='Dodano do koszyka';					
		// 			// location.reload();
		// 		},
		// 		error: function(response) {
		// 			_main.popMessage('cart_error');
		// 			_main.debug?console.log('error: ', response):'';
		// 		}
		// 	});
		// });

		$('.woocommerce-ordering select').change(function() {
			this.form.submit();
		});

		$('.search-trigger').on('click', function() {
			$(this).toggleClass('active');
			$('.search-form').toggleClass('show');
		});

		$('.filters-reveal').on('click', function() {
			$(this).toggleClass('closed').toggleClass('open');
			$(this).parent('.shop-filters').toggleClass('reveal');
			$(this).parent('.shop-filters').find('.has-filters').slideToggle();
		});

		$('#show-mini-cart').on('click', function(e) {
			e.preventDefault();
			$('.mini-cart-box').addClass('show');
			$('main').addClass('noScroll');
		});
		$('.mini-cart-box .close').on('click', function() {
			$('.mini-cart-box').removeClass('show');
			$('main').removeClass('noScroll');
		});
		
		$('.change_qty').on('click', function() {
			var change = $(this).attr('data-change');
			if ($(this).hasClass('checkout')) {
				console.log('ok');
				var this_input = $(this).parent('.product-quantity').find('.quantity').find('.qty');
				switch (change) {
					case 'less':						
						if (this_input.val() > 1) {	
							this_input.val( function(i, oldval) {
								return --oldval;
							});
						}
					break;
					case 'more':
						// $(this).parent('.product-quantity').find('.quantity').find('.qty').val('999');
						if (this_input.val() < 10) {	
							this_input.val( function(i, oldval) {
								return ++oldval;
							});
						}
					break;
				}
			} else {
				switch (change) {
					case 'less':
						if ($('#qty').val() > 1) {	
							$('#qty').val( function(i, oldval) {
								return --oldval;
							});
						}
					break;
					case 'more':
						if ($('#qty').val() < 10) {
							$('#qty').val( function(i, oldval) {
								return ++oldval;
							});
						}
					break;
				}
			}
		});

		$('body').on('click', '.close-pop', function() {
			_main.killPops();
		});

		$('body').on('click', 'a', function(e) {
			if ($(this).attr('href') == '#') {
				e.preventDefault();
			} else if ($(this).attr('href') == '#reload') {
				location.reload();				
			}	
		});

	},
	addToCart: function() {
		
	},
	getViewport: function () {
		_main = this;
		wh = $(window).width();
		if (wh <= _main.breakpointMobile) {
			_main.viewport = 'mobile';
		} else if (wh > _main.breakpointMobile && wh <= _main.breakpointTablet) {
			_main.viewport = 'tablet';
		} else {
			_main.viewport = 'desktop';
		}
		(_main.debug) ? console.log('viewport: ', _main.viewport) : '';		
	},
	init: function () {
		_main = this;
		_main.getViewport();
		_main.events();					
		_main.handleSliders();					
		_main.handleGalleries();					
		// _main.handleWaypoints();					
		_main.cookies.checkCookies();
	},

	hasNav: false,
	debug: true,
	viewport: null,
	globalNavState: false,
	breakpointMobile: 640,
	breakpointTablet: 1100,
	cookieName: 'komarki_website',

	configPageId: 156,
	backend_base: 'http://localhost/komarki2/',
	backend_url: 'http://localhost/komarki2/index.php/wp-json/wp/v2/',
	backend_url_acf: 'http://localhost/komarki2/index.php/wp-json/acf/v3/'
}



$(document).ready(function () {
	app.init();
});

$(window).on('load', function () {
	app.windowLoaded();
});

$(window).resize(function () {
	app.windowResized();
});

$(document).scroll(function () {
	app.windowScrolled();
});

});
